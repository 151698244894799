import React from 'react'
import TableRowComponent from './tableRowComponent'

export const TableComponent = ({userStateRole, tests, isFinishTable, tableType}) => {
  return (
    <table className="homepage__table">
      <thead>
        <tr className="homepage__tr table__tr--heading">
          <th className="homepage__th">
            <span>Date</span>
          </th>
          <th className="homepage__th">
            <span>Assessment Type</span>
          </th>
          {userStateRole === "manager" ? <th className="homepage__th"><span>Contractor</span></th> : null}
          {<th className="homepage__th">
            <span>{isFinishTable ? "Result" : "Status"}</span>
          </th>}
          {(isFinishTable) ? null : <th className="homepage__th"><span>Action</span></th>}
        </tr>
      </thead>

      <tbody>
        {tests.length !== 0 ? tests.map((test, index) => {
          return <TableRowComponent userStateRole={userStateRole} test={test} isFinishTable={isFinishTable} key={index} tableType={tableType}/>
        }) : null}
      </tbody>
    </table>
)
}