import React, { Fragment, useState } from 'react'

import { apiSavePreTestAnswers } from '../../../api/apiCommands'

export const PreQuestionComponent = ({preTestQuestion, userStateToken, quizId, getQuizQuestions, clearPreTestQuestionState}) => {
  const [radioIsChecked, setRadioIsChecked] = useState(null)

  const submitHandler = async (evt) => {
    evt.preventDefault()
    const answerId = evt.target.elements.answer.value
    apiSavePreTestAnswers(userStateToken, quizId, answerId)
      .then(() => {
        clearPreTestQuestionState()
        getQuizQuestions()
      })
  }

  return (
    <form className="test__pre-form" onSubmit={submitHandler}>
      <h3>{preTestQuestion.description}</h3>
      <p>{preTestQuestion.text}</p>
      <fieldset className="test__fieldset test__fieldset--pre">
        {preTestQuestion.answers.map((answer, index) => {
          return (
            <Fragment key={index}>
              <input 
                className="test__radiobutton" 
                type="radio" 
                name="answer" 
                id={`question-${answer.id}`} 
                value={`${answer.id}`} 
                onChange={() => setRadioIsChecked(true)}
                required />
              <label className="test__radio-label" htmlFor={`question-${answer.id}`}>
                <span>{index + 1}</span>
                <span>{answer.text}</span>
              </label>
            </Fragment>
          )
        })}
      </fieldset>
      <button className="test__pre-button button" type="submit" disabled={!radioIsChecked}>Complete the pre-test</button>
    </form>
  )
}