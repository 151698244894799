import React from 'react'

export default function LoadingButtonComponent() {
  return (
    <div className="button-loading">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
