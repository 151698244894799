import { BASE_URL } from './apiConfig'

const postSomething = async (url, body) => {
  return await fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-type' : 'application/json'
    }
  })
}

export const apiAuthorization = async (form) => {
  try {
    const response = await postSomething(`${BASE_URL}/api/login_check`, form) 
    return response.json()
  } catch (error) {
    throw new Error(error)
  }
}

export const apiForgotPassword = async (form) => {
  try {
    const response = await postSomething(`${BASE_URL}/api/forgot-password`, form);
    return response.json();
  } catch (error) {
    throw new Error(error);
  }
}

export const apiCheckForgotPasswordLink = async (form) => {
  try {
    const response = await postSomething(`${BASE_URL}/api/check-forgot-password-link`, form);
    return response.json();
  } catch (error) {
    throw new Error(error);
  }
}

export const apiResetPassword = async (form) => {
  try {
    const response = await postSomething(`${BASE_URL}/api/reset-password`, form);
    return response.json();
  } catch (error) {
    throw new Error(error);
  }
}

// request for a list of completed and active user tests

export const apiGetTests = async (token) => {
  try {
    const response = await fetch(`${BASE_URL}/api/request/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    if (response.status === 401) {
      localStorage.removeItem('userData')

      return
    }

    return await response.json()
  } catch (error) {
    throw new Error(error)
  }
}

export const apiDownloadTestPdf = async (token, quizId) => {
  try {
    const response = await fetch(`${BASE_URL}/api/quiz/result/${quizId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    const blob = await response.blob()
    const newBlob = new Blob([blob], { type: 'application/pdf' })
    const data = window.URL.createObjectURL(newBlob)
    const link = document.createElement('a')
    link.href = data
    link.download = `quiz-${quizId}-result.pdf`
  
    document.body.appendChild(link)
  
    link.click()
    link.parentNode.removeChild(link)
  
    setTimeout(function () {
      window.URL.revokeObjectURL(data)
    }, 100)
  } catch (error) {
    throw new Error(error)
  }
}

export const apiGetWelcomeText = async (token, quizId) => {
  try {
    const response = await fetch(`${BASE_URL}/api/request/company/${quizId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return await response.json()
  } catch (error) {
    throw new Error(error)
  }
}

// quests related queries

export const apiGetQuizQuestions = async (token, quizId) => {
  try {
    const response = await fetch(`${BASE_URL}/api/request/get/${quizId}/quiz`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return await response.json()
  } catch (error) {
    throw new Error(error)
  }
}

export const apiSaveQuizAnswers = async (token, quizId, quizData) => {
  try {
    const response = await fetch(`${BASE_URL}/api/quiz/save/${quizId} `, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(quizData)
    })
    return await response.json()
  } catch (error) {
    throw new Error(error)
  }
}

// preQuests related queries

export const apiGetPreTestQuestions = async (token, quizId) => {
  try {
    const response = await fetch(`${BASE_URL}/api/request/get/${quizId}/pre-test-question`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return await response.json()
  }
    catch (error) {
    throw new Error(error)
  }
}

export const apiSavePreTestAnswers = async (token, quizId, answerId ) => {
  try {
    const response = await fetch(`${BASE_URL}/api/request/set/${quizId}/pre-test-answer/${answerId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(answerId)
    })
    return await response.json()
  } catch (error) {
    throw new Error(error)
  }
}

// reviews related queries

export const apiGetReviewQuiz = async (token, reviewId) => {
  try {
    const response = await fetch(`${BASE_URL}/api/request/review/${reviewId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    return await response.json()
  } catch (error) {
    throw new Error(error)
  }
}

export const apiSaveReviewQuiz = async (token, reviewId, quizData) => {
  try {
    const response = await fetch(`${BASE_URL}/api/request/review/save/${reviewId} `, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(quizData)
    })
    return await response.json()
  } catch (error) {
    throw new Error(error)
  }
}