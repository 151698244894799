import React, {useEffect, useState} from "react";
import logoImg from "../../assets/icon-logo.svg";
import {apiCheckForgotPasswordLink, apiResetPassword} from "../../api/apiCommands";
import {useHistory, useParams} from "react-router-dom";

const ResetPasswordPage = () => {
  const history = useHistory();
  const {token} = useParams();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    apiCheckForgotPasswordLink({token})
      .then((response) => {
        setErrors({});
        if ('success' === response.status) {
        } else {
          let responseErrors = {};

          if (response?.reason) {
            responseErrors.reason = response?.reason;
          }

          setErrors(responseErrors);

          setTimeout(() => {
            history.push('/');
          }, 2000);
        }
      })
      .catch((error) => {
      });
  }, []);

  const onChangePassword = (event) => {
    setPassword(event.target.value);
  }

  const onChangePasswordConfirmation = (event) => {
    setPasswordConfirmation(event.target.value);
  }

  const onSubmit = () => {
    apiResetPassword({
        token,
        password,
        password_confirmation: passwordConfirmation
    })
      .then((response) => {
        setErrors({});
        if ('success' === response.status) {
          alert('Password changed successfully.');
          setTimeout(() => {
            history.push('/login');
          }, 1000);
        } else {
          let responseErrors = {};

          for (const errorsKey in response.errors) {
            const errorMessage = response.errors[errorsKey];
            const newErrorKey = errorsKey.replace('[','').replace(']', '');
            responseErrors[newErrorKey] = errorMessage;
          }

          if (response?.reason) {
            responseErrors.reason = response?.reason;
          }

          setErrors(responseErrors);
        }
      })
      .catch((error) => {
      });
  }

  const isValidForm = () => {
    return Object.keys(errors).length === 0;
  }

  return (
    <div className="homepage">

      <section className="homepage__section container">
        <header className="homepage__header">
          <img className="homepage__logo-image" loading="lazy" src={logoImg} width="330" height="auto"
               alt="Information Consultants"/>
        </header>

        <div className="heading">
          <h2>Enter your new password below.</h2>
        </div>

        {! isValidForm() &&
          <div className="form-errors">
            <ul>
              {Object.values(errors).map((error, index) => (
                <li key={index} className='error'>{error}</li>
              ))}
            </ul>
          </div>
        }

        <div className="form-reset-container">
          <div className="form-group">
            <label id="password">
              New password <span className='required-field'>*</span>
            </label>
            <input
              className="form-control"
              type="password"
              name='password'
              value={password}
              onChange={onChangePassword}
            />

            <label id="password_confirmation">
              New password (Confirmation) <span className='required-field'>*</span>
            </label>
            <input
              className="form-control"
              type="password"
              name='password_confirmation'
              value={passwordConfirmation}
              onChange={onChangePasswordConfirmation}
            />

            <div className="form-group">
              <button type="button" onClick={onSubmit} className="button btn-submit">Reset password</button>
            </div>

          </div>
        </div>

      </section>
    </div>
  )
}

export default ResetPasswordPage;