import React, { useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'

export const ReviewFieldsetComponent = ({currentQuestion, fieldIndex, nextQustionHandler, messageList, setMessageList}) => {
  const [commentIsRequired, setCommentIsRequired] = useState(messageList[fieldIndex] ? true : false)
  const [inputValue, setInputValue] = useState(messageList[fieldIndex] ? messageList[fieldIndex] : "")
  const reviewFieldset = useRef(null)
  
  const labelClickHandler = (bool) => {
    if (bool) {
      setMessageList(messageList.map((message, index) => {
        if (index === fieldIndex) {
          return inputValue
        } else {
          return message
        }
      }))
      
      setTimeout(() => {
        nextQustionHandler()
      }, 500)
    } else {
      setCommentIsRequired(true)
    }
  }

  return (
    <fieldset ref={reviewFieldset} className={fieldIndex !== currentQuestion ? 'visually-hidden' : 'test__fieldset test__fieldset--rev'} >
      <CSSTransition in={commentIsRequired} timeout={300} classNames="popup-animation" unmountOnExit>
        <div className="test__user-answer test__user-answer--review">
          <label htmlFor={`review-message-${fieldIndex}`}>Please explain your choice</label>
          <input 
            name={`review-message-${fieldIndex}`} 
            id={`review-message-${fieldIndex}`} 
            type="text"
            onChange={evt => {
              setInputValue(evt.target.value)
            }}
            value={inputValue}
            placeholder="comment" 
          />
          <button type="button" onClick={() => labelClickHandler(true)} disabled={!inputValue}>
            Сontinue
          </button>
        </div>
      </CSSTransition>
      
      <input className="test__radiobutton" type="radio" name={`review-${fieldIndex}`} id={`review-manager-${fieldIndex}`} value={`manager`} />
      <label 
        className="test__radio-label test__radio-label--review" 
        htmlFor={`review-manager-${fieldIndex}`} 
        onClick={() => labelClickHandler(false)}
      >
        Option №1 - Override contactor
      </label>

      <input className="test__radiobutton" type="radio" name={`review-${fieldIndex}`} id={`review-contractor-${fieldIndex}`} value={`contractor`} />
      <label 
        className="test__radio-label test__radio-label--review" 
        htmlFor={`review-contractor-${fieldIndex}`} 
        onClick={() => labelClickHandler(false)}
      >
        Option №2 - Override manager
      </label>

      <input className="test__radiobutton" type="radio" name={`review-${fieldIndex}`} id={`review-not-change-${fieldIndex}`} value={`not_change`} />
      <label 
        className="test__radio-label test__radio-label--review" 
        htmlFor={`review-not-change-${fieldIndex}`} 
        onClick={() => labelClickHandler(true)}
      >
        Option №3 - Do nothing
      </label>
    </fieldset> 
  )
}
