import {useDispatch, useSelector} from "react-redux";
import {hideForgotPasswordModalAction} from "../../../redux/modalSlice";
import React, {useState} from "react";
import {apiForgotPassword} from "../../../api/apiCommands";

const ForgotPasswordModal = () => {
    const dispatch = useDispatch();
    const isForgotPassword = useSelector(state => state.modals.isForgotPassword);
    const [errors, setErrors] = useState({});
    const [email, setEmail] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const onChangeEmail = (event) => {
        setEmail(event.target.value);
    }

    const onClickCancel = () => {
        dispatch(hideForgotPasswordModalAction());
    }

    const onClickReset = () => {
        setErrors({});
        setSuccessMessage('');

        apiForgotPassword({
            'email': email,
        })
        .then((response) => {
            if ('success' === response.status) {
                setEmail('');
                setSuccessMessage(response.message);
            } else {
                let responseErrors = {};
                for (const errorsKey in response.errors) {
                    const errorMessage = response.errors[errorsKey];
                    const newErrorKey = errorsKey.replace('[','').replace(']', '');
                    responseErrors[newErrorKey] = errorMessage;
                }

                if (response?.reason) {
                    responseErrors.reason = response?.reason;
                }

                setErrors(responseErrors);
            }
        })
        .catch((error) => {
        });
    }

    const isValidForm = () => {
        return Object.keys(errors).length === 0;
    }

    return (
      <div className="forgot-password-modal">
          {isForgotPassword &&
            <div className="forgot-password-modal-content">
                <div className="forgot-password-modal-content-heading">
                    <span>Please enter your email address. You will receive a link to create a new password via email</span>
                </div>

                <input
                  className="test__pre-form"
                  type="text"
                  name="text"
                  placeholder="Your email address"
                  value={email}
                  onChange={onChangeEmail}
                />

                {! isValidForm() &&
                  <div className="forgot-password-modal-content-errors">
                      {Object.values(errors).map((error) => (
                        <div className="forgot-password-modal-content-error">{error}</div>
                      ))}
                  </div>
                }

                { successMessage.length > 0 &&
                    <div className="forgot-password-modal-content-success">
                        <p>{successMessage}</p>
                    </div>
                }

                <button type="button" onClick={onClickCancel} className="btn btn-cancel">Cancel</button>
                <button type="button" onClick={onClickReset} className="btn btn-reset">Get new password</button>
            </div>
          }
      </div>
    );
}

export default ForgotPasswordModal;