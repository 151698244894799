import React, { useState } from 'react'

import LoadingButtonComponent from '../general/loadingButtonComponent'

export default function DownloadButtonComponent({downloadPdfHandler, result}) {
  const [pdfIsLoading, setPdfIsLoading] = useState(false)
  
  const downloadButtonHandler = async () => {
    setPdfIsLoading(true)
    try {
      await downloadPdfHandler(result.id)
      setPdfIsLoading(false)
    } catch (error) {
      setPdfIsLoading(false)
    }
  }

  return (
    <button className="homepage__table-button button button--table" onClick={() => downloadButtonHandler()} disabled={pdfIsLoading}>
      {pdfIsLoading ? <LoadingButtonComponent /> : <span>Download PDF</span>}
    </button>
  )
}
