import {createSlice} from '@reduxjs/toolkit';

const initialState =  {
  isForgotPassword: false,
}

const modals = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    showForgotPasswordModalAction(state, action) {
      state.isForgotPassword = true;
    },

    hideForgotPasswordModalAction(state, action) {
      state.isForgotPassword = false;
    },
  },
});

export default modals.reducer;
export const {showForgotPasswordModalAction, hideForgotPasswordModalAction} = modals.actions