import React, { useEffect, useState } from 'react'

export const QuestResumeComponent = ({quizQuestions, showFormAnswers}) => {
  const [answers, setAnswers] = useState([])
  const [buttonIsDisabled, setButtonIsDisabled] = useState(true)

  useEffect(() => {
    const answers = showFormAnswers()

    setAnswers(answers)
    setButtonIsDisabled(answers.includes(null))
  }, [])

  const answersComponent = quizQuestions.map((question, index) => {
    return (
      <li className="test__answer-item" key={index}>
        <span className="test__question-number">{index + 1}</span>
        <div>
          <p className="test__question-text">{question.text}</p>
          <p className={answers[index] ? "test__question-answer" : "test__question-answer test__question-answer--empty"}>
            {answers[index] ? <span>You answer is:</span> : null}
            {answers[index] ? `${answers[index]}` : `You haven't answered this question yet`}
          </p>
        </div>
      </li>
    )
  })

  return (
    <div className="test__resume-container">
      <h2>Your answers to the quiz:</h2>
      <div className="test__resume-answers-container">
        <ol className="test__answers-list">
          {answersComponent}
        </ol>
      </div>
      <button className="test__resume-button button" type="submit" disabled={buttonIsDisabled}>Complete the test</button>
    </div> 
  )
}