import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { apiDownloadTestPdf } from '../../../api/apiCommands'
import DownloadButtonComponent from './downloadButtonComponent'

export default function TableRowButtonComponent({isFinishTable, test}) {
  const userStateToken = useSelector(state => state.userData.token)
  const userStateRole = useSelector(state => state.userData.userRole)

  const downloadPdfHandler = async (quizId) => {
    try {
      await apiDownloadTestPdf(userStateToken, quizId)
    }
    catch (error) {
      throw new Error(error)
    }
  }

  const buttonDownload = 
    <div className="homepage__cell-container">
      {test.results ? test.results.map((result, index) => {
        return <DownloadButtonComponent downloadPdfHandler={downloadPdfHandler} result={result} key={index}/>
      }) : null}
    </div>

  const buttonTestLink = 
    <div className="homepage__cell-container">
      <Link className="homepage__test-link button button--table" to={`/test/${test.id}`}>Open Test</Link>
    </div>

  const buttonReviewLink =
    <div className="homepage__cell-container">
      <Link className="homepage__test-link button button--table" to={`/review/${test.id}`}>Needs review</Link>
    </div>

  // if this is a finished test, then the button will download the pdf file
  // if active, there will be a link to pass the test
  // if return, there will be a link to review the test
  // if undefined it will be nool

  return (
    <Fragment>
      {isFinishTable ? 
        <Fragment>{buttonDownload}</Fragment> : 
        ((test.status === undefined && userStateRole === "manager") || test.status === 'Awaiting Manager Response') ? 
          null :
          (test.status === 'Returned') ? 
            <Fragment>{buttonReviewLink}</Fragment>  : 
            <Fragment>{buttonTestLink}</Fragment>
      }
    </Fragment>
  )
}