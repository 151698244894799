import React from "react";
import { BrowserRouter, Route } from 'react-router-dom';
import {useRoutes} from './routes';
import { QueryParamProvider } from 'use-query-params';

function App() {
  const routes = useRoutes();
  
  return (
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        {routes}
      </QueryParamProvider>
    </BrowserRouter>
  );
}

export default App;
