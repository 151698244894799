import { combineReducers } from "redux";
import  userSlice  from "./userSlice";
import testsSlice from "./testSlice";
import modalsSlice from "./modalSlice";

const rootReducer = combineReducers({
  userData: userSlice,
  testsData: testsSlice,
  modals: modalsSlice
});

export default rootReducer;