import React, { Fragment, useRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { CircularProgressbar } from 'react-circular-progressbar'
import "react-circular-progressbar/dist/styles.css"

import { setReloadStatus } from '../../../redux/testSlice'
import { LoadingComponent } from '../general/loadingComponent'
import { QuestionFieldsetComponent } from './questionFieldsetComponent'
import { QuestResumeComponent } from './questionResumeComponent'
import { LinearProgressBarComponent } from '../general/linearProgressBar'
import { apiSaveQuizAnswers } from '../../../api/apiCommands'

import {ReactComponent as ReactChevronLefttSvg}  from '../../../assets/icon-left.svg'
import {ReactComponent as ReactChevronRighttSvg}  from '../../../assets/icon-right.svg'

export const QuestionComponent = ({quiz, userStateToken, endTheTest}) => {
  const quizId = quiz.id
  const dispatch = useDispatch()
  let quizQuestions = quiz.questions
  const quizPostQuestions = quiz['post_test_questions']
  quizQuestions = quizQuestions.concat(quizPostQuestions)
  const quizStartTime = moment()

  const testForm = useRef(null)
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const localTestData = JSON.parse(localStorage.getItem('testData'))
  const [userAnswers, setUserAnswers] = useState((localTestData && localTestData.testAnswers) ? 
    (localTestData.testAnswers[quiz.id] ? localTestData.testAnswers[quiz.id] : []) : [])
  const [error, setError] = useState(null)

  const submitHandler = async (evt) => {
    evt.preventDefault()

    const quizFinishTime = moment()
    const quizTime = quizFinishTime.diff(quizStartTime)
    const duration = moment.duration(quizTime).milliseconds()

    const postAnswers = []
    const answers = userAnswers.slice().filter((answer, index) => {
      let bool = true
      if (quizPostQuestions.length > 0) {
        quizPostQuestions.forEach((postQuestion) => {
          if (answer.questionId === postQuestion.id) {
            postAnswers.push(userAnswers[index])
            bool = false
          }
        })
      }

      return bool
    })

    let quizData = {
      duration,
      answers: answers,
      post_test_answers: postAnswers
    }

    setIsLoading(true)

    apiSaveQuizAnswers(userStateToken, quizId, quizData)
      .then((response) => {
        if (response.status && response.status === "error") {
          setError(response.reason)
          setIsLoading(false)
        } else {
          endTheTest()
        }
      })
      .catch((error) => {
        console.log("Error: ", error)
        setError("Something goes wrong")
        setIsLoading(false)
      })
  }

  const changeUserAnswers = (userAnswer) => {
    const newUserAnswers = userAnswers.filter(answer => answer.questionId !== userAnswer.questionId).concat([userAnswer])
    const localTestData = JSON.parse(localStorage.getItem('testData'))

    localStorage.setItem('testData', JSON.stringify({...localTestData, testAnswers: {...localTestData.testAnswers, [quiz.id]: newUserAnswers}}))
    setUserAnswers(newUserAnswers)
  }

  const showFormAnswers = () => {
    const formNode = testForm.current
    const formObjects = formNode.elements

    return quizQuestions.map((answer, index) => {
      const value = formObjects[`answer-${index}`].value
      const inputNode = formObjects[`question-${value}`]

      if (inputNode && inputNode.dataset) {
        return inputNode.dataset.text
      }
      return null
    })
  }

  const nextQustionHandler = () => {
    setCurrentQuestion(currentQuestion + 1)
    if (currentQuestion >= quizQuestions.length) {
      setCurrentQuestion(quizQuestions.length)
    }
  }

  const previousQustionHandler = () => {
    setCurrentQuestion(currentQuestion - 1)
    if (currentQuestion <= 0) {
      setCurrentQuestion(0)
    }
  }

  useEffect(()=> {
    // if (currentPage == 1) {

    // }
    console.log(currentQuestion);
  }, [])

  
  const progressPercent = Math.floor((currentQuestion + 1) / quizQuestions.length * 100)

  return (
    <Fragment>
      <div className="test__progress-box">
        <CircularProgressbar 
          className="test__progress" 
          value={progressPercent} 
          text={(currentQuestion + 1) <= quizQuestions.length ? `${currentQuestion + 1} / ${quizQuestions.length}` : 'Complete'} 
          strokeWidth={6}
          styles={{
            path: {
              stroke: `rgba(195, 54, 211, ${progressPercent / 100})`,
            },
            text: {
              fontSize: '16px',
            },}
          }
        />
      </div>

      <div className="test__linear-progress">
        <LinearProgressBarComponent completed={progressPercent} />
        <span>
          {(currentQuestion + 1) > quizQuestions.length ? "" : `Question ${currentQuestion + 1} / ${quizQuestions.length}`}
        </span>
      </div>
      
      
      <form className="test__form" onSubmit={submitHandler} ref={testForm} noValidate>
        {quizQuestions.map((question, fieldIndex) => {
          return (
            <QuestionFieldsetComponent 
              key={fieldIndex}
              currentQuestion={currentQuestion}
              fieldIndex={fieldIndex}
              question={question}
              nextQustionHandler={nextQustionHandler}
              changeUserAnswers={changeUserAnswers}
              userAnswers={userAnswers}
            />
          )
        })}

        {currentQuestion === quizQuestions.length ? <QuestResumeComponent quizQuestions={quizQuestions} showFormAnswers={showFormAnswers}/> : null}
      </form>

      {(isLoading) &&
        <div>
          <LoadingComponent addComponents={
            <div className="loading__additional-container">
              <span>This process may take up to five minutes. There is no need to hangout, we will take it from here.</span>
              <div className="loading__button-container">
                <Link className="homepage__button button" to={`/`} onClick={() => dispatch(setReloadStatus(false))}>
                  Return to list
                </Link>
              </div>
            </div>
          }>
            <span>Generating results...</span>
          </LoadingComponent>
        </div>
      }

      {error ? <span className="test__error-span">{error}</span> : null}

      <div className="test__switch-container">
        <button className="test__switch-button test__switch-button--prev" type="click" onClick={() => previousQustionHandler()} disabled={currentQuestion === 0}>
          <ReactChevronLefttSvg className="test__switch-svg" />
          Prev question
        </button>
        <button className="test__switch-button test__switch-button--next" type="click" onClick={() => nextQustionHandler()} disabled={currentQuestion === quizQuestions.length}>
          <ReactChevronRighttSvg className="test__switch-svg" />
          Next question
        </button>
      </div>
    </Fragment>
  )
}