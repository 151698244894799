import React from 'react'
import { Link } from 'react-router-dom'

import {ReactComponent as ReactLinkedinSvg}  from '../../../assets/icon-linkedin.svg'
import {ReactComponent as ReactFacebookSvg}  from '../../../assets/icon-facebook.svg'
import {ReactComponent as ReactTwitterSvg}  from '../../../assets/icon-twitter.svg'

export const TestPageEndComponent = () => {
  return (
    <div className="completion">
      <main className="completion__section">
        <h1 className="visually-hidden">Exam is complete!</h1>
        <p>
          Your exam is complete! Thank you for taking the time to go through the compliance process. 
          ICON will evaluate your responses. ICON will be in touch with you soon to discuss 
          the next steps in your onboarding process.
        </p>
        <Link className="completion__link" to={`/`}>Check results</Link>
      </main>

      <footer className="completion__footer">
        <ul className="completion__list">
          <li className="completion__item">
            <span className="completion__tag">Get in Touch with ICON</span>
          </li>
          <li className="completion__item">
            <address className="completion__adress">100 Waugh Drive, Suite 300 Houston, TX 77007</address>
          </li>
          <li className="completion__item">
            <a className="completion__phone-link" href="tel:+713.438.0919">
              713.438.0919
              <br></br>
              Toll Free: 1.877.374.3216
            </ a>
          </li>
          <li className="completion__item">
            <ul className="completion__social-list">
              <li className="completion__social-item">
                <a className="completion__social-link" href="https://www.linkedin.com/company/icon-information-consultants/" rel="noreferrer" target="_blank" >
                  <ReactLinkedinSvg className="completion__social-svg" />
                  <span>Linkedin link</span>
                </a>
              </li>
              <li className="completion__social-item">
                <a className="completion__social-link" href="https://www.facebook.com/ICONInformationConsultants" rel="noreferrer" target="_blank">
                  <ReactFacebookSvg className="completion__social-svg" />
                  <span>Facebook link</span>
                </a>
              </li>
              <li className="completion__social-item">
                  <a className="completion__social-link" href="https://www.facebook.com/ICONInformationConsultants" rel="noreferrer" target="_blank">
                    <ReactTwitterSvg className="completion__social-svg" />
                    <span>Facebook link</span>
                  </a>
                </li>
            </ul>
          </li>
        </ul>
      </footer>
    </div>
  )
}