import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import rootReducer from './redux/rootReducer'
import App from './js/App'
import './sass/style.scss'

const middleware = getDefaultMiddleware({
  immutableCheck: false,
  serializableCheck: false,
  thunk: true,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
})

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
