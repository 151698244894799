import React from 'react'

export function LoadingComponent({children, addComponents}) {
  return (
    <div className="loading">
      {children}
      <div className="loading-circle">
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
      </div>
      {addComponents}
    </div>
  )
}
