import React, { Fragment, useEffect, useState, useRef } from 'react'
import { TextListComponent } from './qestionTextListComponent'

export const QuestionFieldsetComponent = ({currentQuestion, fieldIndex, question, nextQustionHandler, changeUserAnswers, userAnswers}) => {
  const fieldsetRef = useRef(null)
  const [newQuestion, setNewQuestion] = useState(question)
  const [questionTextList, setQuestionTextList] = useState([question.text])
  const [questionAnswers, setQuestionAnswers] = useState(question.answers)
  const [questionId, setQuestionId] = useState(question.id)
  const [subQuestionNumber, setSubQuestionNumber] = useState(0)
  const [currentAnswer, setCurrentAnswer] = useState(null)

  useEffect(() => {
    if (userAnswers) {
      const currentAnswer = userAnswers.filter((answer) => {
        return answer.questionId === questionId
      })[0]

      if (currentAnswer && newQuestion.answers) {
        const questionAnswer = newQuestion.answers.filter((answer) => {
          return Number(answer.id) === Number(currentAnswer.answerId)
        })[0]

        if (questionAnswer && questionAnswer.question) {
          setNewQuestion(questionAnswer.question)
        }

        questionsInit(questionAnswer, currentAnswer)
      }
    }
  }, [questionId])

  const showClarifyingQuestions = (evt, answer) => {
    const filedsetNode = fieldsetRef.current

    if (answer.question) {
      const newQuestText = [answer.question.text].concat(questionTextList)
      setQuestionTextList(newQuestText)
      setQuestionAnswers(answer.question.answers)
      setSubQuestionNumber(subQuestionNumber + 1)
      setQuestionId(answer.question.id)

      Array.from(filedsetNode.elements).forEach(input => {
        input.checked = false
      })
    }
     else {
      setTimeout(() => {
        nextQustionHandler()
      }, 500)
     }

     const questionAnswer = {
      questionId: questionId,
      answerId: evt.target.value
    }

    setCurrentAnswer(questionAnswer)
    changeUserAnswers(questionAnswer)
  }

  const questionsInit = (qanswers, answer) => {
    const filedsetNode = fieldsetRef.current

    if (qanswers && qanswers.question) {
      const newQuestText = [qanswers.question.text].concat(questionTextList)
      setQuestionTextList(newQuestText)
      setQuestionAnswers(qanswers.question.answers)
      setSubQuestionNumber(subQuestionNumber + 1)
      setQuestionId(qanswers.question.id)

      Array.from(filedsetNode.elements).forEach(input => {
        input.checked = false
      })
    }

    const questionAnswer = {
      questionId: answer.questionId,
      answerId: answer.answerId
    }

    setCurrentAnswer(questionAnswer)
    changeUserAnswers(questionAnswer)
  }

  return (
    <fieldset className={fieldIndex !== currentQuestion ? 'visually-hidden' : 'test__fieldset'} ref={fieldsetRef}>
      <div className="test__question-container">
        <TextListComponent subQuestionNumber={subQuestionNumber} questionTextList={questionTextList} />

        <p className={subQuestionNumber !== 0 ? "test__fieldset-intro test__fieldset-intro--old" : "test__fieldset-intro"}>
          {question.description}
        </p>

      </div>
      <div className="test__answers-container">
      {questionAnswers.map((answer, index) => {
        return (
          <Fragment key={index}>
            <input 
              className="test__radiobutton"
              type="radio" 
              name={`answer-${fieldIndex}`}
              id={`question-${answer.id}`}
              value={`${answer.id}`}
              data-text={`${answer.text}`}
              checked={currentAnswer ? Number(currentAnswer.answerId) === Number(answer.id) : false}
              onChange={(evt) => showClarifyingQuestions(evt, answer)}
              required
            />
            <label className="test__radio-label" htmlFor={`question-${answer.id}`}>
              <span>{index + 1}</span>
              <span>{answer.text}</span>
            </label>
          </Fragment>
        )
      })}
      </div>
    </fieldset> 
  )
}

