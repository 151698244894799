import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiAuthorization } from '../api/apiCommands'

const initialState =  {
  userRole: null,
  expires: null,
  token: null,
  refreshToken: null,
  status: null
}

export const getUserDataFromApi = createAsyncThunk(
  'userData/getUserDataFromApi',
  async (form) => {
    return apiAuthorization(form)
  }
)

const userSlice = createSlice({
  name: 'userData',

  initialState,
  
  reducers: {
    setNewState(state, action) {
      state.userRole = action.payload.role
      state.expires = action.payload.expires
      state.token = action.payload.token
      state.refreshToken = action.payload.refresh_token
    },

    setNewStatus(state, action) {
      state.status = action.payload
    },

    nullifyUserState(state) {
      state.userRole = null
      state.expires = null
      state.token = null
      state.refreshToken = null
      state.status = null
    }
  },

  extraReducers: {
    [getUserDataFromApi.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getUserDataFromApi.fulfilled]: (state, action) => {
      const response = action.payload

      if (response.code === 401) {
        state.status = response.message
      }

      else {
        state.userRole = action.payload.role
        state.expires = action.payload.expires
        state.token = action.payload.token
        state.refreshToken = action.payload.refresh_token
        state.status = 'success'
      }
    },

    [getUserDataFromApi.rejected]: (state, action) => {
      state.status = 'failed'
    },
  }
})

export default userSlice.reducer
export const { setNewState, setNewStatus, nullifyUserState } = userSlice.actions