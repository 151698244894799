import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useQueryParams, StringParam } from 'use-query-params';
import { CSSTransition } from 'react-transition-group';

import { apiAuthorization } from '../../api/apiCommands';
import { setNewState } from '../../redux/userSlice';

import logoImg from '../../assets/icon-logo.svg';
import previewImg from '../../assets/icon-preview.svg';
import {ReactComponent as ReactCheckboxSvg}  from '../../assets/checkbox.svg';
import {ReactComponent as ReactLinkedinSvg}  from '../../assets/icon-linkedin.svg';
import {ReactComponent as ReactFacebookSvg}  from '../../assets/icon-facebook.svg';
import {ReactComponent as ReactTwitterSvg}  from '../../assets/icon-twitter.svg';
import ForgotPasswordModal from "../components/ForgotPasswordModal/ForgotPasswordModal";
import {showForgotPasswordModalAction} from "../../redux/modalSlice";

export const AuthorizationPage = () => {
  const dispatch = useDispatch();

  const [query, setQuery] = useQueryParams({
    role: StringParam,
    token: StringParam
  });

  useEffect(() => {
    if (query.role && query.token) {
      localStorage.removeItem('userData');
      dispatch(setNewState({role: query.role, token: query.token }));
      localStorage.setItem('userData', JSON.stringify({token: query.token, role: query.role}));
    }
  }, [dispatch, query]);

  const [submitIsEnable, setSubmitIsEnable] = useState(true);
  const [checkboxState, setCheckboxState] = useState(false);
  const [validationError, setValidationError] = useState(null);
  const [form, setForm] = useState({
    email: "",
    password: ""
  });
  const isForgotPassword = useSelector(state => state.modals.isForgotPassword);
  
  const changeCheckboxState = event => {
    setCheckboxState(event.target.checked)
  }

  const changeFormHandler = event => {
    setForm({...form, [event.target.name]: event.target.value})
  }

  const onClickForgotPassword = () => {
    dispatch(showForgotPasswordModalAction());
  }

  const setNewError = message => {
    setValidationError(message)
    setTimeout(() => {
      setValidationError(null)
    }, 3500);
  }

  const loginHandler = async (evt) => {
    evt.preventDefault()
    setSubmitIsEnable(false)
    try {
      await apiAuthorization(form)
        .then(({ code = 200, message, token, expires, refresh_token, role}) => {
          if (code === 200) {
            dispatch(setNewState({role, token, expires, refresh_token}))

            /*if (checkboxState) {*/
              localStorage.setItem('userData', JSON.stringify({token, refresh_token, role, expires}))
            /*}*/
          } else {
            setSubmitIsEnable(true)
            setNewError(message)
          }
        })
        .catch(error => {
          setSubmitIsEnable(true)
          const errorMessage = error.message === 'Invalid credentials.'
            ? 'Invalid password'
            : error.message === 'Email could not be found.'
            ? 'Email not found'
            : 'Something went wrong. Try again'
            setNewError(errorMessage)
        })      
    } catch (error) {
      throw error
    }
  }

  return (
    <div className="autorization">
      {isForgotPassword && <ForgotPasswordModal />}
      <section className="autorization__section">
        <header className="autorization__header">
          <img className="autorization__logo-image" src={logoImg} width="330" height="auto" alt="Information Consultants" />
          <h1 className="visually-hidden">Welcome to Information Consultants website</h1>
        </header>

        <div className="autorization__login-container">
          <img className="autorization__preview-image" src={previewImg} width="645" height="auto" loading="lazy" alt="form preview" />

          <form className="autorization__form">
            <h2>Welcome, please login below.</h2>

            <div className="autorization__alert-container">
              <CSSTransition in={validationError !== null} timeout={1000} classNames="autorization__alert-animation" unmountOnExit>
                <span className="autorization__alert">{validationError}</span>
              </CSSTransition>
            </div>

            <fieldset className="autorization__fieldset">
              <ul className="autorization__form-list">
                  <li className="autorization__form-item">
                    <label className="visually-hidden" htmlFor="userEmail">Email Addres</label>
                    <input className="autorization__form-input" type="text" name="email" id="userEmail" onChange={changeFormHandler} required />
                    <span className="autorization__form-placeholder">Email Addres</span>
                  </li>
                  <li className="autorization__form-item">
                    <label className="visually-hidden" htmlFor="userPassword">Password</label>
                    <input className="autorization__form-input" type="password" name="password" id="userPassword" onChange={changeFormHandler} required />
                    <span className="autorization__form-placeholder">Password</span>
                  </li>
                </ul>
            </fieldset>

            <fieldset className="autorization__fieldset">
              <div className="autorization__checkbox-container">
                <input className="autorization__checkbox visually-hidden" type="checkbox" id="rememberMe" name="rememberMe" onChange={changeCheckboxState} />
                <label className="autorization__checkbox-label" htmlFor="rememberMe">
                  <ReactCheckboxSvg className="autorization__checkbox-svg" />
                  <span>Remember me</span>
                </label>
              </div>
              <a className="autorization__forget-link" href="#" onClick={onClickForgotPassword}>Forgot Password?</a>
            </fieldset>

            <button className="autorization__button button" type="submit" onClick={loginHandler} disabled={!submitIsEnable}>Login Now</button>

            <div className="autorization__social-container">
              <h3>Connect with ICON!</h3>
              <ul className="autorization__social-list">
                <li className="autorization__social-item">
                  <a className="autorization__social-link" href="https://www.linkedin.com/company/icon-information-consultants/" rel="noreferrer" target="_blank" >
                    <ReactLinkedinSvg className="autorization__social-svg" />
                    <span>Linkedin link</span>
                  </a>
                </li>
                <li className="autorization__social-item">
                  <a className="autorization__social-link" href="https://www.facebook.com/ICONInformationConsultants" rel="noreferrer" target="_blank">
                    <ReactFacebookSvg className="autorization__social-svg" />
                    <span>Facebook link</span>
                  </a>
                </li>
                <li className="autorization__social-item">
                  <a className="autorization__social-link" href="https://www.facebook.com/ICONInformationConsultants" rel="noreferrer" target="_blank">
                    <ReactTwitterSvg className="autorization__social-svg" />
                    <span>Facebook link</span>
                  </a>
                </li>
              </ul>
            </div>
          </form>
        </div>
      </section>
    </div>
  )
}