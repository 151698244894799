import React from 'react'

export function LinearProgressBarComponent({completed}) {
  const fillerStyles = {
    width: `${completed}%`,
    height: '100%',

    textAlign: 'right',
    
    backgroundColor: `rgba(195, 54, 211, ${completed / 100})`,
    borderRadius: 'inherit',
    
    transition: 'width 0.7s ease-in-out',
  }

  return (
    <div className="linear-progress">
      <div className="linear-progress__fill" style={fillerStyles}>
        <span className="linear-progress__label">{`${completed >= 100 ? 100 : completed}%`}</span>
      </div>
    </div>
  )
}