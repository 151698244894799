import React, { useEffect, useState, useRef, Fragment } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgressbar } from 'react-circular-progressbar'

import { ReviewFieldsetComponent } from '../components/reviewPageComponents/reviewFieldsetComponent'
import { ReviewResumeComponent } from '../components/reviewPageComponents/reviewResumeComponent'
import { LinearProgressBarComponent } from '../components/general/linearProgressBar'
import { LoadingComponent } from '../components/general/loadingComponent'
import { setReloadStatus } from '../../redux/testSlice'
import { apiGetReviewQuiz, apiSaveReviewQuiz } from '../../api/apiCommands'

import logoImg from '../../assets/icon-logo.svg'
import {ReactComponent as ReactHomeSvg}  from '../../assets/icon-home.svg'
import {ReactComponent as ReactChevronLefttSvg}  from '../../assets/icon-left.svg'
import {ReactComponent as ReactChevronRighttSvg}  from '../../assets/icon-right.svg'
import {ReactComponent as ReactBurgerSvg} from '../../assets/icon-burger.svg'

export const ReviewPage = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const reviewId = useParams().id
  const testForm = useRef(null)
  const [menuIsOpen, setMenuIsopen] = useState(false)
  const userStateToken = useSelector(state => state.userData.token)

  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [reviewIsLoading, setReviewIsLoading] = useState(false)
  const [questions, setQuestions] = useState([])
  const [postQuestions, setPostQuestions] = useState([])
  const [messageList, setMessageList] = useState([])

  useEffect(() => {
    apiGetReviewQuiz(userStateToken, reviewId)
      .then(({quiz}) => {
        console.log("review quiz: ", quiz)
        const questionsList = quiz.questions.concat(quiz.post_test_questions)
        setPostQuestions(quiz.post_test_questions)
        setQuestions(questionsList)

        setMessageList(questionsList.map(() => {
          return false
        }))

        console.log('review questionsList: ', questionsList)

        setReviewIsLoading(false)
      })
      .catch(error => {
        history.push('/')
      })
  }, [])

  const nextQustionHandler = () => {
    setCurrentQuestion(currentQuestion + 1)
    if (currentQuestion >= questions.length) {
      setCurrentQuestion(questions.length)
    }
  }

  const previousQustionHandler = () => {
    setCurrentQuestion(currentQuestion - 1)
    if (currentQuestion <= 0) {
      setCurrentQuestion(0)
    }
  }

  const formSubmitHandler = (evt) => {
    evt.preventDefault()

    const formObjects = evt.target.elements
    const postAnswers = []
    const answers = questions.map((question, index) => {
      return {
        questionId: question.id,
        type: formObjects[`review-${index}`].value,
        message: formObjects[`review-message-${index}`] ? formObjects[`review-message-${index}`].value : ""
      }
    })

    if (postQuestions.length > 0) {
      postQuestions.forEach(() => {
        const postAnswer = answers.pop()
        console.log(postAnswer)
        postAnswers.push(postAnswer)
      })
    }

    let quizData = {
      answers,
      post_test_answers: postAnswers
    }

    console.log("quizData: ", quizData)

    setReviewIsLoading(true)

    apiSaveReviewQuiz(userStateToken, reviewId, quizData)
      .then(() => {
        history.push('/')
      })
      .catch(() => {
        history.push('/')
      })
  }

  const showFormAnswers = () => {
    const formNode = testForm.current
    const formObjects = formNode.elements

    return questions.map((answer, index) => {
      return formObjects[`review-${index}`].value
    })
  }

  const showFormComments = () => {
    const formNode = testForm.current
    const formObjects = formNode.elements

    return questions.map((answer, index) => {
      const commentObject = formObjects[`review-message-${index}`]
      return commentObject ? commentObject.value : null
    })
  }

  const progressPercent = Math.floor((currentQuestion + 1) / questions.length * 100)

  return (
    <div className="test">
      <section className="test__section">
        <header className="test__header">
          <h1 className="visually-hidden">Tests list page</h1>
          <img className="test__logo-image" loading="lazy" src={logoImg} width="250" height="auto" alt="Information Consultants" />
          <button className="test__menu-button" onClick={() => setMenuIsopen(!menuIsOpen)}>
            <ReactBurgerSvg />
            Open menu
          </button>
        </header>
        {!reviewIsLoading ? 
          <main className="test__main-container test__main-container--review">
          {currentQuestion !== questions.length &&
            <h2>
              ICON’s compliance process has evaluated your compliance exam responses and First Contractor’s responses.
              ICON has identified a conflict that needs to be resolved, as follows
            </h2>
          }

          {currentQuestion !== questions.length ? 
            <div className="test__linear-progress">
              <LinearProgressBarComponent completed={progressPercent} />
              <span>
                {(currentQuestion + 1) > questions.length ? "" : `Сonflict ${currentQuestion + 1} / ${questions.length}`}
              </span>
            </div> : null
          }
          
          {(questions.length !== 0 && questions[currentQuestion]) &&
            <div className="test__resume-answers-container">
              <div className="test__user-answer">
                <span>{questions[currentQuestion].managerText}</span>
                <span>You responded: {questions[currentQuestion].managerAnswer}</span>
              </div>
              <div className="test__user-answer">
                <span>{questions[currentQuestion].text}</span>
                <span>Contractor responded: {questions[currentQuestion].contractorAnswer}</span>
              </div>
            </div>
          }

          {currentQuestion !== questions.length ? 
            <Fragment>
              <span className="test__aware">Because of the conflict, you have the option to take one of the following actions:</span>
              <p className="test__aware-description">
                Once you make your selection, your response will be recorded. If you select Option №3, 
                please note that the conflict will remain in place and a compliance specialist from 
                ICON will reach out to you to discuss the matter. For compliance purposes, 
                it is important that your responses and the contractor’s responses match. 
                Please respond truthfully and to the best of your ability.
              </p>
            </Fragment> : null}
          
            <div className="test__progress-box">
              <CircularProgressbar 
                className="test__progress" 
                value={progressPercent} 
                text={(currentQuestion + 1) <= questions.length ? `${currentQuestion + 1} / ${questions.length}` : 'Complete'} 
                strokeWidth={6}
                styles={{
                  path: {
                    stroke: `rgba(195, 54, 211, ${progressPercent / 100})`,
                  },
                  text: {
                    fontSize: '16px',
                  },}
                }
              />
            </div>

            <form className="test__form" ref={testForm} onSubmit={formSubmitHandler}>
              {questions.length !== 0 ? questions.map((question, index) => {
                return (
                  <ReviewFieldsetComponent 
                    currentQuestion={currentQuestion} 
                    fieldIndex={index}
                    question={question}
                    nextQustionHandler={nextQustionHandler}
                    messageList={messageList}
                    setMessageList={setMessageList}
                    key={index}
                  />
                )
              }) : null}

              {(currentQuestion === questions.length && questions.length !== 0) ? 
                <ReviewResumeComponent quizQuestions={questions} showFormAnswers={showFormAnswers} showFormComments={showFormComments} /> 
                : null
              }
            </form>
            <div className="test__switch-container">
              <button className="test__switch-button test__switch-button--prev" type="click" onClick={() => previousQustionHandler()} disabled={currentQuestion === 0}>
                <ReactChevronLefttSvg className="test__switch-svg" />
                Prev question
              </button>
              <button className="test__switch-button test__switch-button--next" type="click" onClick={() => nextQustionHandler()} disabled={currentQuestion === questions.length}>
                <ReactChevronRighttSvg className="test__switch-svg" />
                Next question
              </button>
            </div>
          </main> :
          <div className="test__loading-container">
            <LoadingComponent addComponents={
              <div className="loading__additional-container">
                <span>This process may take up to five minutes. There is no need to hangout, we will take it from here.</span>
                <div className="loading__button-container">
                  <Link className="homepage__button button" to={`/`}>Return to list</Link>
                </div>
              </div>
            }>
              <span>Generating results...</span>
            </LoadingComponent>
          </div>
        }

        <footer className="test__footer"></footer>
      </section>

      <aside className={menuIsOpen ? "test__aside test__aside--open" : "test__aside"}>
        <button className="test__close-button" onClick={() => setMenuIsopen(!menuIsOpen)}>Close</button>
        <ul className="test__aside-list">
          <li className="test__aside-item">
            <Link className="test__aside-link" to={`/`} onClick={() => () => dispatch(setReloadStatus(false))}>
              <ReactHomeSvg className="test__aside-svg" />
              <span>Home</span>
            </Link>
          </li>
        </ul>
      </aside>

      <div className={menuIsOpen ? "test__aside-overflow test__aside-overflow--open" : "test__aside-overflow"} onClick={() => setMenuIsopen(!menuIsOpen)}></div>
    </div>
  )
}
