import React, { useEffect, useState } from 'react'

export const ReviewResumeComponent = ({quizQuestions, showFormAnswers, showFormComments}) => {
  const [answers, setAnswers] = useState([])
  const [buttonIsDisabled, setButtonIsDisabled] = useState(true)

  const giveNewAnswer = (answer) => {
    switch(answer) {
      case 'manager':
        return 'Override contactor'
      case 'contractor': 
        return 'Override manager'
      default:
        return 'Do nothing'
    }
  }

  useEffect(() => {
    const answers = showFormAnswers()
    setAnswers(answers)
    setButtonIsDisabled(answers.includes(""))
  }, [])

  const answersComponent = quizQuestions.map((question, index) => {
    const comments = showFormComments()
    const currentComment = ![...comments][index] ? "No comment" : [...comments][index]

    return (
      <li className="test__answer-item" key={index}>
        <span className="test__question-number">{index + 1}</span>
        <div>
          <p className="test__question-text">{question.text}</p>
          <p className={answers[index] ? "test__question-answer" : "test__question-answer test__question-answer--empty"}>
            {answers[index] ? <span>You decided to:</span> : null}
            {answers[index] ? `${giveNewAnswer(answers[index])}` : `You have not yet decided how to resolve this conflict`}
          </p>
          <p className="test__question-comment">
            {answers[index] ? <span>Comment:</span> : null}
            {answers[index] ? currentComment : null}
          </p>
        </div>
      </li>
    )
  })

  return (
    <div className="test__resume-container">
      <h2>Result of the quiz:</h2>
      <div className="test__resume-answers-container">
        <ol className="test__answers-list">
          {answersComponent}
        </ol>
      </div>
      <button className="test__resume-button button" type="submit" disabled={buttonIsDisabled}>Apply changes</button>
    </div> 
  )
}