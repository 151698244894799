import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

export const TextListComponent = ({subQuestionNumber, questionTextList}) => {
  const textEl = useRef(null)
  const remideatinCaption = useSelector(state => state.testsData.remideatinCaption)

  useEffect(() => {
    textEl.current.scrollIntoView({block: "center", behavior: "smooth" })
  }, [questionTextList])

  return (
    <div className="test__text-list" ref={textEl}>
      <TransitionGroup className="test__todo-list">
          {questionTextList.map((text, index) => {
            return (
              <CSSTransition timeout={1000} classNames="test__fieldset-question-animation" key={index}>
                <p className={index === 0 ? "test__fieldset-question" : "test__fieldset-question test__fieldset-question--old"} >
                  <span className={(subQuestionNumber === index && index !== 0) ? "test__fieldset-alert" : "visually-hidden"}>
                    {remideatinCaption ? remideatinCaption : ""}
                  </span>
                  {text}
                </p>
              </CSSTransition>
            )
          })}
        </TransitionGroup>
    </div> 
  )
}