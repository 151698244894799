import React, { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { nullifyUserState } from '../../redux/userSlice'
import { getTestsFromApi } from '../../redux/testSlice'
import { TableComponent } from '../components/homePageComponents/tableComponent'
import { LoadingComponent } from '../components/general/loadingComponent'

import {ReactComponent as ReactLogoutSvg}  from '../../assets/icon-logout.svg'
import logoImg from '../../assets/icon-logo.svg'

export const HomePage = () => {
  const dispatch = useDispatch()

  const userStateToken = useSelector(state => state.userData.token)
  const userStateRole = useSelector(state => state.userData.userRole)
  const activeTests = useSelector(state => state.testsData.activeTests)
  const finishedTests = useSelector(state => state.testsData.finishedTests)
  const testsStatus = useSelector(state => state.testsData.status)
  const reloadStatus = useSelector(state => state.testsData.reloadStatus)

  const logoutHandler = () => {
    localStorage.removeItem('userData')
    localStorage.removeItem('testData')
    localStorage.removeItem('acknowledge')
    dispatch(nullifyUserState())
  }

  // getting lists of tests, adding them to the state
  
  useEffect(() => {
    if (reloadStatus) {
      console.log("im reload tests")
      dispatch(getTestsFromApi(userStateToken))
      .then((response) => {
        // console.log(response.payload.requests['remediation_caption'])
      })
      .catch((error) => {
        logoutHandler()
      })
    }
  }, [reloadStatus])

  return (
    <div className="homepage">
      
      <section className="homepage__section container">
        {testsStatus === 'loading' ? 
          <LoadingComponent>
            <span>Please wait...</span>
          </LoadingComponent> :
          <Fragment>
            <header className="homepage__header">
              <img className="homepage__logo-image" loading="lazy" src={logoImg} width="330" height="auto" alt="Information Consultants" />
              <h1 className="visually-hidden">Tests list page</h1>
              {!reloadStatus && <span>Your test results are still being processed, please wait...</span>}
              <button className="homepage__button button" type="button" onClick={logoutHandler}>
                <ReactLogoutSvg className="homepage__button-svg" />
                Log out
              </button>
            </header>

            <div className="homepage__active-quizzes">
              <h2>Active Tests</h2>
              {activeTests.length !== 0 ? 
                <TableComponent userStateRole={userStateRole} tests={activeTests} isFinishTable={false} tableType={!reloadStatus ? "active" : null}/> :
                <span className="homepage__quizzes-span">No active tests</span>
              }
            </div>

            <div className="homepage__finished-quizzes">
              <h2>Finished Tests</h2>
              {finishedTests.length !== 0 ? 
                <TableComponent userStateRole={userStateRole} tests={finishedTests} isFinishTable={true} tableType={"finish"} /> :
                <span className="homepage__quizzes-span">No finished tests</span>
              }
            </div>
          </Fragment>
        }
      </section>
    </div>
  )
}