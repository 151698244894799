import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { setReloadStatus } from '../../redux/testSlice'

import { TestPageMainComponent } from '../components/testPageComponents/testPageMainComponent'
import { TestPageEndComponent } from '../components/testPageComponents/testPageEndComponent'

export const TestPage = () => {
  const dispatch = useDispatch()
  const [testIsComplete, setTestIsComplete] = useState(false)

  const endTheTest = () => {
    dispatch(setReloadStatus(true))
    setTestIsComplete(true)
  }

  if (testIsComplete) {
    return <TestPageEndComponent />
  }

  return (
    <TestPageMainComponent endTheTest={endTheTest}/>
  )
}