import React from 'react'
import { Link } from 'react-router-dom'

export const ErrorComponent = ({error}) => {
  return (
    <div className="test__error">
      <div className="test__error-container">
        <span>{error}</span>
        <Link className="test__error-link button" to={`/`}>Return</Link>
      </div>
    </div>
  )
}