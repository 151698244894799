import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'

import TableRowButtonComponent from './tableRowButtonComponent'

export default function TableRowComponent({test, isFinishTable, userStateRole, tableType}) {
  const reloadStatus = useSelector(state => state.testsData.reloadStatus)

  const typeStatusTd = isFinishTable ? 
    <td className="homepage__td" data-name="Result">
      <div className="homepage__cell-container">
        {test.results.length !== 0 ? test.results.map((result, index) => {
          if (result?.test?.name) {
            return <span key={index}>{result?.test?.name} - {result?.status}</span>
          } else {
            return <span key={index}>Test {result?.status}</span>
          }
        }) : <span>Completed</span>}
      </div>
    </td> :
    <td className="homepage__td" data-name="Status"><span>{test.status ? test.status : "not started"}</span></td>
  
  return (
    <tr className="homepage__tr">
      <td className="homepage__td" data-name="Date"><span>{moment(test.created_at).format('MMMM Do YYYY')}</span></td>

      <td className="homepage__td" data-name="Status"><span>{test.revet ? "Re-vet" : "New"}</span></td>

      {test.contractor? <td className="homepage__td" data-name="Contractor"><span>{test.contractor}</span></td> : null}

      {typeStatusTd}

      {((isFinishTable)) ? null :
        <td className="homepage__td" data-name="Action">
          {((tableType === "finish") || (reloadStatus && (!tableType))) && <TableRowButtonComponent isFinishTable={isFinishTable} test={test}/>}
        </td>
      }
    </tr>
  )
}