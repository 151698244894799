import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, Link, useHistory } from 'react-router-dom'

import { QuestionComponent } from './questionComponent'
import { PreQuestionComponent } from './questionPreComponent'
import { ErrorComponent } from './questionErrorComponent'

import { apiGetWelcomeText, apiGetQuizQuestions, apiGetPreTestQuestions } from '../../../api/apiCommands'
import { setUserAware, setUserAcknowledge } from '../../../redux/testSlice'

import logoImg from '../../../assets/icon-logo.svg'
import {ReactComponent as ReactHomeSvg} from '../../../assets/icon-home.svg'
import {ReactComponent as ReactCheckboxSvg} from '../../../assets/checkbox.svg'
import {ReactComponent as ReactBurgerSvg} from '../../../assets/icon-burger.svg'

export const TestPageMainComponent = ({endTheTest}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const quizId = useParams().id

  const [preTestQuestion, setPreTestQuestion] = useState(null)
  const [companyMessage, setCompanyMessage] = useState(null)
  const [newQuiz, setNewQuiz] = useState(null)
  const [error, setError] = useState(null)
  const [awareIsChecked, setAwareIsChecked] = useState(false)
  const [menuIsOpen, setMenuIsopen] = useState(false)

  const localTestData = JSON.parse(localStorage.getItem('testData'))
  const acknowledgeData = JSON.parse(localStorage.getItem('acknowledge'))
  const userStateToken = useSelector(state => state.userData.token)
  const userData = JSON.parse(localStorage.getItem('userData'))
  const [userIsAware, setUserIsAware] = useState(localTestData ? localTestData.userIsAware : false)

  const [userIsAcknowledge, setUserIsAcknowledge] = useState(acknowledgeData ? acknowledgeData.userIsAcknowledge : false)
  const activeTests = useSelector(state => state.testsData.activeTests)
  
  const getQuizQuestions = () => {
    apiGetQuizQuestions(userStateToken, quizId)
    .then(({status, quiz}) => {
      if(status === 'error') {
        setError('test not found')
      }
      setNewQuiz(quiz)
      console.log('quiz: ', quiz)
    })
    .catch(error => {
      history.push('/')
    }) 
  }

  const getQuizPreQuestions = () => {
    apiGetPreTestQuestions(userStateToken, quizId)
    .then(({pre_test_question}) => {
      setPreTestQuestion(pre_test_question)
    })
    .catch(error => {
      history.push('/')
    }) 
  }

  const getWelcomeText = () => {
    apiGetWelcomeText(userStateToken, quizId)
    .then((response) => {
      setCompanyMessage(response.company)
    })
    .catch(error => {
      history.push('/')
    }) 
  }

  const clearPreTestQuestionState = () => {
    setPreTestQuestion(null)
  }

  useEffect(() => {
    let isPreTestQuestion = false

    activeTests.forEach(test => {
      if (Number(test.id) === Number(quizId)) {
        isPreTestQuestion = test.has_pre_test_question
      }
    })

    if (!userIsAware) {
      getWelcomeText()
    }

    if (isPreTestQuestion) {
      getQuizPreQuestions()
    } else {
      getQuizQuestions()
    }
  }, [])

  const changeLocalTestData = () => {
    localStorage.setItem('testData', JSON.stringify({userIsAware: true}))
    setUserIsAware(true)
    dispatch(setUserAware(true))
  }

  const changeLocalTestDataforAcknowledge = () => {
    localStorage.setItem('acknowledge', JSON.stringify({userIsAcknowledge: true}))
    setUserIsAcknowledge(true)
    dispatch(setUserAcknowledge(true))
  }

const quizScreen = ()=>{
  return  <> 
    {newQuiz ? 
      <QuestionComponent 
        quiz={newQuiz}
        userStateToken={userStateToken}
        endTheTest={endTheTest}
        activeTest={activeTests[0]}
      />
       : null}

    {preTestQuestion ? 
      <PreQuestionComponent 
        preTestQuestion={preTestQuestion} 
        userStateToken={userStateToken} 
        quizId={quizId}
        getQuizQuestions={getQuizQuestions}
        clearPreTestQuestionState={clearPreTestQuestionState}
      /> : null}
  </>
}

const acknowledgeScreenforContractor = ()=>{
  return  <div className="test__main-container--welcome"> 
      <h2>Contractor Acknowledgment<br/>Compliance with Independent Contractor Laws</h2>
      <div className="test__pre-question-container">In most cases, the extent of control and direction exercised over an independent contractor by a company (or its managers) in connection with the contractor’s work is the most important factor to consider. The prevailing rule of thumb: the greater the level of control and direction to which an independent contractor is subjected, the more likely the independent contractor will be deemed to be misclassified. “Misclassified” means, according to applicable law, that the contractor should have been classified as an employee instead of being “misclassified” as an independent contractor.<br/>Since you would like to be engaged as an independent contractor, it is important you know some of the common reasons how “misclassification” occurs. Remember: if you are deemed to be “misclassified”, a whole host of issues may arise. For example, the business expenses you take as a “misclassified” contractor may be reversed and added back into your income, thus causing you to incur a new tax liability for that previously unreported income plus interest for failure to timely pay, plus potential penalties and fines.<br/>As such, you are required to know and understand these rules, and to follow them:</div>
      <div className="test__pre-question-container">1. Look Like a Bona Fide Independent Business. Maintain separate bank accounts for personal and business matters (do not comingle funds between accounts!); obtain all necessary licenses and permits to carry on your business and provide services; carry business insurance at all times; do not ask for or accept employment benefits from your customer or the service recipient under any circumstance (e.g. health insurance, paid vacation, pensions, stock options); market and/or promote your business regularly, preferably to the general public but also through your private networks; and, importantly, work for multiple clients at the same time, or, if the nature or size of your work requires you to work full-time for one customer, then only do so for a limited duration (to the extent possible) and at least try to work for other customers in the same calendar (or fiscal) year and/or line up new customers to start as soon as your current project ends. A key factor here is to show that you have an active, independent business with a desire to acquire more work and NOT be dependent on one customer. <input type="checkbox" name="" id="" /></div>
      <div className="test__pre-question-container">2. Retain Control over YOUR Business. Following these guidelines will help you show that you are the one in charge on the job, and that you have a bona fide independent business. You want to show that you are bringing your expertise, experience, and know-how to the job.</div>
      <div className="test__pre-question-container">While an employee is generally subject to the business’s instructions about when, where, and how to work, a bona fide independent contractor is not. Following are examples of common types of instructions:<br/>
        When and where to do the work.<br/>
        How to do the work. What tools or equipment to use.<br/>
        What workers to hire or to assist with the work.<br/>
        Where to purchase supplies and services.<br/>
        What work must be performed by a specified individual.<br/>
        What order or sequence to follow when performing the work.<br/>
        Bottom line: the greater the level of instruction, direction, and control exercised over you, the greater the likelihood you will be deemed to be misclassified.
      </div>
      <div className="test__pre-question-container">In addition, if the company to whom you are providing services provides or arranges for work training, this is a sign they expect work to be performed in a certain way or that you otherwise are not a real, bona fide, independent business with experience and proper know-how for the job. <input type="checkbox" name="" id="" /></div>
      <div className="test__pre-question-container">Training can occur in informal settings such as when requiring you to attend meetings about work, to having you work alongside an employee who is more experienced.</div>
      <div className="test__pre-question-container">Bottom line: training is a form of direction and control that is viewed very disfavorably under applicable law because an independent contractor ought to be functioning as an independent business that brings its experience and know-how to the job (versus having to be trained on how to do the job).</div>
      <div className="test__pre-question-container">You are required to avoid direction and control, or to receive only a small, insignificant amount of direction and control. Further, training on how to do the job must be avoided, or it can only occur, if absolutely necessary, to an insignificant and/or very minor degree.</div>
      <div className="test__pre-question-container">You hereby acknowledge that you have read, understand, and will follow the directives set forth in this Special Acknowledgment.</div>
      <div className="test__pre-question-container"><input className="e-signature" type="text" placeholder="Type in your name here as an e-signature (E-signature Box)"/> <input className="e-signature" type="date" placeholder="Type in your name here as an e-signature (E-signature Box)" style={{width: 10+'%'}} /> </div>
      <button className="begin-button button" onClick={() => changeLocalTestDataforAcknowledge()}>BEGIN ASSESSMENT > </button>
  </div>
}

const acknowledgeScreenforManager = ()=>{
  return  <div className="test__main-container--welcome"> 
      <h2>Manager Acknowledgment<br/>Compliance with Independent Contractor Laws</h2>
      <div className="test__pre-question-container">In most cases, the extent of control and direction exercised over an independent contractor by your company (including you) in connection with the contractor’s work is the most important factor to consider. The prevailing rule of thumb: the greater the level of control and direction to which the independent contractor is subjected, the more likely the independent contractor will be deemed to be misclassified. “Misclassified” means, according to applicable law, that the contractor should have been classified as an employee instead of being “misclassified” as an independent contractor.</div>
      <div className="test__pre-question-container">Reminder: if “misclassified”, a whole host of potential issues and problems arise, such as claims for unpaid wages, unpaid overtime, penalties and interest, back taxes, entitlement to benefits and perquisites of employment, the automatic payment of attorney’s fees, and reputational damage. The amount of damages, penalties, and attorneys’ fees can become quite significant. For example, just imagine, an independent contractor claiming “misclassification” can look back up to 3 years for overtime pay. While an employee gets overtime pay, an independent contractor does not. Thus, if successful, a “misclassification” claim allows the contractor to be reclassified as an employee, obtain overtime pay looking back up to 3 years, plus interest, attorneys’ fees, and other potential penalties and damages.</div>
      <div className="test__pre-question-container">Misclassification often arises when a disgruntled contractor is terminated early, prior to completion of a project or before the relationship was expected to end, or when a project ends but the contractor cannot find other work. Many other circumstances can also trigger a claim for misclassification, including simple things such as jealousy (e.g., contractor is jealous that employees who are doing similar work are getting stock options and other benefits of employment while the contractor struggles to make ends meet), to getting injured on the job (e.g., a contractor may not have business interruption insurance or other financial means to bridge the gap if injured, and it is not uncommon for a contractor at that time to simply claim that he/she was actually misclassified and, therefore, should be entitled to worker’s compensation benefits under your company’s policy). </div>
      <div className="test__pre-question-container">As such, following are 3 critical areas that often lead to misclassification that you need to know. If you are engaging workers as independent contractors, you are required to know and understand these rules, and to follow them: </div>
      <div className="test__pre-question-container"><b>1. Instructions:</b> An employee is generally subject to the business’s instructions about when, where, and how to work, whereas a bona fide independent contractor generally is not. Following are examples of common types of instructions: </div>
      <div className="test__pre-question-container">When and where to do the work.<br/>
      How to do the work.<br/>
      What tools or equipment to use.<br/>
      What workers to hire or to assist with the work.<br/>
      Where to purchase supplies and services.<br/>
      What work must be performed by a specified individual.<br/>
      What order or sequence to follow when performing the work.</div>
      <div className="test__pre-question-container">Bottom line: the greater the level of instruction given by you or your company to the independent contractor, the greater the likelihood the contractor is “misclassified”. Therefore, you are required to avoid direction and control, or to provide only a small, insignificant amount of direction and control when necessary. <input type="checkbox" name="" id="" /></div>
      <div className="test__pre-question-container">2. Training: If your company provides or arranges for work training for the contractor, this is a sign you expect work to be performed in a certain way; therefore, the worker is much more likely to be deemed your employee.</div>
      <div className="test__pre-question-container">Training can occur in informal settings such as when requiring the contractor to attend meetings about work, to having the contractor work alongside an employee who is more experienced.</div>
      <div className="test__pre-question-container">Bottom line: training is a form of direction and control, and it is viewed very disfavorably under applicable law because an independent contractor ought to be functioning as an independent business that brings its own experience and know-how to the job (versus having to be trained on how to do the job). Therefore, training on how to do the job must be avoided, or it can only occur, if absolutely necessary, to an insignificant and/or very minor degree.</div>
      <div className="test__pre-question-container">3. Setting the Order or the Sequence of the Work: If you or your company determines what gets done when, in any particular order or sequence, that indicates you (or your company) control how the work is performed. While it is OK to establish larger deliverables and milestones and then let the contractor control the details of how to accomplish those deliverables and milestones, it is not OK to control the ordering or the sequencing of work on a day-to-day or other regular basis. Such high-level of interaction and control over the contractor is a strong indication that the contractor ought to be reclassified as an employee, or, stated differently, that the contractor is “misclassified”. </div>
      <div className="test__pre-question-container">Bottom line: Focus on giving independent contractors projects with deliverables (and/or milestones), and avoid exercising control and direction over the details of the work like you would, or like you would have the right to do, with an employee. Therefore, avoid setting the order or the sequence of the contractor’s work, or if absolutely necessary, only do it sporadically and only for small or atypical parts of the work.</div>
      <div className="test__pre-question-container">You hereby acknowledge that you have read, understand, and will follow the directives set forth in this Special Acknowledgment.</div>
      <div className="test__pre-question-container"><input className="e-signature" type="text" placeholder="Type in your name here as an e-signature (E-signature Box)"/> <input className="e-signature" type="date" placeholder="Type in your name here as an e-signature (E-signature Box)" style={{width: 10+'%'}} /> </div>
      <button className="begin-button button" onClick={() => changeLocalTestDataforAcknowledge()}>BEGIN ASSESSMENT > </button>
  </div>
}

  return (
    <div className="test">
      <section className="test__section">
        <header className="test__header">
          <h1 className="visually-hidden">Tests list page</h1>
          <img className="test__logo-image" loading="lazy" src={logoImg} width="250" height="auto" alt="Information Consultants" />
          <button className="test__menu-button" onClick={() => setMenuIsopen(!menuIsOpen)}>
            <ReactBurgerSvg />
            Open menu
          </button>
        </header>

        {!error ? <Fragment>
          {(companyMessage && !userIsAware) ? 
            <main className="test__main-container test__main-container--welcome">
              <h2>Please click here to acknowledge your obligation to answer all questions truthfully and to the best of your ability</h2>
              <div className="test__pre-question-container" dangerouslySetInnerHTML={{__html: `${companyMessage.text.replace(/\n\s*\n/g, '<br /><br />')}`}}></div>
              <div className="test__pre-question-container"dangerouslySetInnerHTML={{__html: `${companyMessage.checkbox.replace(/\n\s*\n/g, '<br /><br />')}`}}></div>
              <div className="test__checkbox-container">
                <input 
                  className="test__checkbox visually-hidden"
                  type="checkbox"
                  id="awareCheck"
                  name="awareCheck"
                  onChange={() => setAwareIsChecked(!awareIsChecked)}
                />
                <label className="test__checkbox-label" htmlFor="awareCheck">
                  <ReactCheckboxSvg className="test__checkbox-svg" />
                </label>
              </div>

              <button className="test__aware-button button" onClick={() => changeLocalTestData()} disabled={!awareIsChecked}>Continue</button>
            </main> :
            <main className="test__main-container">
              {(!userIsAcknowledge) ?
                (userData.role == 'contractor')?
                  acknowledgeScreenforContractor():
                  acknowledgeScreenforManager()
               : quizScreen()}
            </main>}
            <footer className="test__footer"></footer>

        </Fragment> : <ErrorComponent error={error}/>}
      </section>

      <aside className={menuIsOpen ? "test__aside test__aside--open" : "test__aside"}>
        <button className="test__close-button" onClick={() => setMenuIsopen(!menuIsOpen)}>Close</button>
        <ul className="test__aside-list">
          <li className="test__aside-item">
            <Link className="test__aside-link" to={`/`}>
              <ReactHomeSvg className="test__aside-svg" />
              <span>Home</span>
            </Link>
          </li>
        </ul>
      </aside>

      <div className={menuIsOpen ? "test__aside-overflow test__aside-overflow--open" : "test__aside-overflow"} onClick={() => setMenuIsopen(!menuIsOpen)}></div>
    </div>
  )
}