import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetTests } from '../api/apiCommands'

const initialState =  {
  activeTests: [],
  finishedTests: [],
  remideatinCaption: "",
  userIsAware: false,
  userIsAcknowledge: false,
  reloadStatus: true,
  status: null,
}

export const getTestsFromApi = createAsyncThunk(
  'teststList/getTestsFromApi',
  async (userToken) => {
    return apiGetTests(userToken)
  }
)

const testsSlice = createSlice({
  name: 'teststList',

  initialState,
  
  reducers: {
    setActiveTests(state, action) {
      state.activeTests = action.payload
    },

    setFinishedTests(state, action) {
      state.finishedTests = action.payload
    },

    setUserAware(state, action) {
      state.userIsAware = action.payload
    },

     setUserAcknowledge(state, action) {
      state.userIsAcknowledge = action.payload
    },

    setReloadStatus(state, action) {
      state.reloadStatus = action.payload
    }
  }, 

  extraReducers: {
    [getTestsFromApi.pending]: (state, action) => {
      state.status = 'loading'
    },

    [getTestsFromApi.fulfilled]: (state, action) => {
      const response = action.payload
      state.status = response.status

      state.activeTests = response.requests.active
      state.finishedTests = response.requests.finished
      state.remideatinCaption = response.requests['remediation_caption']
    },
    
    [getTestsFromApi.rejected]: (state, action) => {
      state.status = 'failed'
    },
  }
})

export default testsSlice.reducer
export const {setActiveTests, setFinishedTests, setUserAware, setUserAcknowledge, setReloadStatus} = testsSlice.actions